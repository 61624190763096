import React from 'react';

const DeliveryColdBanner = () => {
    return (
        <section className="full-banner mt-4" style={{ paddingTop: '0px'}}>
            <div className="info-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="info-content">
                                <h4> <i className="fa fa-warning" aria-hidden="true"></i>Importante</h4>
                                <p><strong>Los pedidos que ingresen del 6/1/25 al 10/1/25 serán enviados el día 13/1/25</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DeliveryColdBanner;