import React, { useRef, useEffect } from 'react';
//import Loader from '../extras/Loader';
import Header from '../layout/Header';
import SliderHome from './SliderHome';
import CategorySlider from './CategorySlider';
import CollectionBanner from './CollectionBanner';
import LastProducts from './LastProducts';
import Footer from '../layout/Footer';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { listCategoriesAction } from '../../actions/categoriesActions';
import ShipmentBanner from '../layout/ShipmentBanner';
import DeliveryColdBanner from '../layout/DeliveryColdBanner';

//Función principal
function Home(props) {
    //Inicializo el dispatch
    const dispatch = useDispatch();
    //Genero los refs
    const divRef = useRef();
    
    //Traigo el store de categories
    //const categoriesLoading = useSelector( state => state.categories.loading );
    //const categoriesError = useSelector( state => state.categories.error );
    const categories = useSelector( state => state.categories.categories );
    //Cargo los datos
    useEffect(() => {
        dispatch( listCategoriesAction() );
    }, [dispatch]);
    
    //Manejo el top
    const handleToTop = () => {
        window.scrollTo(0,0);
    }
    //Renderizo
    return (
        <React.Fragment>
            <div className="top" ref={divRef}></div>

            <Header />

            <SliderHome />

            <ShipmentBanner />
            <DeliveryColdBanner />

            <LastProducts />

            <Footer />

            <div className="tap-top" onClick={handleToTop}>
                <div>
                    <i className="fa fa-angle-double-up"></i>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Home;